import axios from 'axios';

export const authService = {

  /** Get Payrolls */

  getPayrollService: (params) => axios.get('/payroll', { auth: true, params: { ...params } }),

  getPayrollSummaryService: (params) => axios.get('/loads/payroll/summary', { auth: true, params: { ...params } }),

  getPayrollLoadsService: (params) => axios.get('/loads/payroll', { auth: true, params: { ...params } }),

  getManualPayrollLoadsService: (params) => axios.get('/loads/payroll/manual', { auth: true, params: { ...params } }),

  createManualPayrollService: (params) => axios.post('/loads/txns/payroll', params, { auth: true }),

  /** End */

};
