/**Get Payrolls */
export const GET_PAYROLLS = 'GET_PAYROLLS';
export const GET_PAYROLLS_SUCCESS = 'GET_PAYROLLS_SUCCESS';

export const GET_PAYROLL_SUMMARY = 'GET_PAYROLL_SUMMARY';
export const GET_PAYROLL_SUMMARY_SUCCESS = 'GET_PAYROLL_SUMMARY_SUCCESS';

export const GET_PAYROLL_LOADS = 'GET_PAYROLL_LOADS';
export const GET_PAYROLL_LOADS_SUCCESS = 'GET_PAYROLL_LOADS_SUCCESS';
export const GET_MANUAL_PAYROLL_LOADS = 'GET_MANUAL_PAYROLL_LOADS';
export const GET_MANUAL_PAYROLL_LOADS_SUCCESS = 'GET_MANUAL_PAYROLL_LOADS_SUCCESS';
export const CREATE_MANUAL_PAYROLL = 'CREATE_MANUAL_PAYROLL';
