import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "utils";

export const reportStyles = makeStyles(() => ({
    arActivityRow: {
        padding: '12px 16px',
        height: '48px',
        background: '#FFFFFF',
        borderRadius: '4px',
        marginBottom: '8px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    arActivityRowYear: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: Colors.TextPrimary,
        '& img': {
            marginRight: '16px',
            filter: 'invert(53%) sepia(25%) saturate(4750%) hue-rotate(110deg) brightness(99%) contrast(103%)'
        },
    },
    exportButton: {
        background: 'transparent',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        color: Colors.ThemeBlue,
        '& img': {
            marginRight: '8px',
        },
    },
    actionsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    exportAndCalendar: {
        display: 'flex',
        alignItems: 'center',
    },
    // totalTab: {
    //     width: '100%',
    //     display: 'flex',
    //     marginTop: '-25px',
    //     height: '50px',
    //     alignItems: 'center',
    //
    //     '& p': {
    //         background: 'rgba(0, 200, 81, 0.15)',
    //         borderRadius: '0 0 0 8px',
    //         width: '20%',
    //         height: '50px',
    //         padding: '0 0 0 16px',
    //         display: 'flex',
    //         alignItems: 'center',
    //     },
    //     '& div': {
    //         background: 'rgba(67, 138, 254, 0.15)',
    //         width: '20%',
    //         height: '50px',
    //         padding: '0 16px',
    //         display: 'flex',
    //         alignItems: 'center',
    //     },
    //     '& span': {
    //         background: 'rgba(67, 138, 254, 0.15)',
    //         height: '50px',
    //         padding: '0 16px',
    //         display: 'flex',
    //         alignItems: 'center',
    //     },
    // },
    totalApplicationTab: {
        width: '100%',
        display: 'flex',
        marginTop: '-25px',
        height: '50px',
        alignItems: 'center',
        '& p': {
            background: 'rgba(0, 200, 81, 0.15)',
            borderRadius: '0 0 0 8px',
            width: '20%',
            height: '50px',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
        },
        '& div': {
            background: 'rgba(67, 138, 254, 0.15)',
            width: '20%',
            height: '50px',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
        },
    },
    arActivityStyles: {
        height: '75vh',
        overflow: 'auto',
    },
    inputWrapper:{
        marginBottom: '16px',
    },
    inputStyle: {
        padding: '12px 16px',
        width: '100%',
        height: '48px',
        background: '#FFFFFF',
        border: '1px solid #438AFE',
        borderRadius: '4px',
    },
    totalTabDetails: {
        width: '100%',
        display: 'flex',
        marginTop: '-25px',
        height: '70px',
        alignItems: 'center',
        paddingBottom:'15px',
        background:'white',
        borderRadius:'0 8px 0 8px',
        '& p': {
            background: 'rgba(0, 200, 81, 0.15)',
            width: '20%',
            height: '50px',
            padding: '0 0 0 16px',
            display: 'flex',
            alignItems: 'center',
            marginTop:'-2px',
        },
        '& div': {
            background: 'rgba(67, 138, 254, 0.15)',
            width: '7%',
            height: '50px',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
            marginTop:'-2px',
        },
    },

}))
