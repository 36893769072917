import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateChancel, SelectTypeAutocomplete, ValidationInput } from 'components';
import { ErrorText, FindLoad, FindSuccessItem, SaveParams, useGlobalStyles, useModal } from 'utils';
import { TxnTypeEnums, txnTypeEnums } from './constants';
import { createLoadStyle } from '../../loads/createLoad/core';
import { agentActions, httpRequestsOnSuccessActions, payrollActions } from 'store';

const ACTION_TYPE = 'CREATE_MANUAL_PAYROLL';
export const ManualTransaction = ({}) => {
  const { officesList, officeAgentList, manualPayrollLoads } = useSelector((state) => ({
    officesList: state.offices.officesList,
    officeAgentList: state.agents.officeAgentList,
    manualPayrollLoads: state.payroll.manualPayrollLoads,
  }));
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState('');
  const globalStyles = useGlobalStyles();
  const loadClass = createLoadStyle();
  const loader = FindLoad(ACTION_TYPE);
  const success = FindSuccessItem(ACTION_TYPE);
  const { close } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const info = history?.location?.state;

  useEffect(() => {
    if (success) {
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      close();
      SaveParams(history, info);
    }
  }, [success]);

  const handleChange = e => {
    if (e.target.name === 'officeId') {
      dispatch(agentActions.getAgentByOffice(e.target.value));
    }
    if (e.target.name === 'agentId') {
      // dispatch(loadActions.getLoadsByStatus({ }));

      dispatch(payrollActions.getManualPayrollLoads({
        agent: e.target.value,
        office: inputs.office,
      }));

      // dispatch(loadActions.getLoad(e.target.value));
    }
    setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    error === e.target.name && setError('');
  };

  const handleCreate = () => {


    const checkAllRequired =
      inputs?.type === TxnTypeEnums.CHARGE ||
      inputs?.type === TxnTypeEnums.CREDIT ||
      inputs?.type === TxnTypeEnums.GROSSPROFIT ||
      inputs?.type === TxnTypeEnums.SERVICEFEE;

    const checkRequiredOffice = inputs?.type === TxnTypeEnums.FLAT_FEE;
    const checkNotRequiredLoad = inputs?.type === TxnTypeEnums.OTHERFEES || inputs?.type === TxnTypeEnums.OFFICE_EXPENSE || inputs?.type === TxnTypeEnums.SOFTWAREFEE;
    const checkRequired =
      checkAllRequired ? inputs?.type && inputs?.officeId && inputs?.agentId && inputs?.loadId && inputs?.amount :
        checkRequiredOffice ? inputs?.type && inputs?.officeId && inputs?.amount :
          checkNotRequiredLoad ? inputs?.type && inputs?.officeId && inputs?.amount :
            '';

    const sendParams = {
      'officeId': inputs?.officeId,
      'agentId': inputs?.agentId,
      'loadId': inputs?.loadId,
      'amount': +inputs?.amount,
      'type': inputs?.type,
      'description': inputs?.description,
    };

    if (checkRequired) {
      dispatch(payrollActions.createManualPayroll(sendParams));
    } else {
      const errorMessages =
        inputs?.type === TxnTypeEnums.FLAT_FEE ?
          !inputs?.type ? 'type' :
            !inputs?.officeId ? 'officeId' :
              !inputs?.amount ? 'amount' : ''
          :

          (inputs?.type === TxnTypeEnums.OTHERFEES || inputs?.type === TxnTypeEnums.OFFICE_EXPENSE || inputs?.type === TxnTypeEnums.SOFTWAREFEE) ?
            !inputs?.type ? 'type' :
              !inputs?.officeId ? 'officeId' :
                !inputs?.amount ? 'amount' : ''
            :

            !inputs.type ? 'type' :
              !inputs.officeId ? 'officeId' :
                !inputs.agentId ? 'agentId' :
                  !inputs.loadId ? 'loadId' :
                    !inputs.amount ? 'amount' :
                      '';
      setError(errorMessages);
    }
  };


  return (
    <div style={{ width: 500 }}>

      <p className="modal-title">Manual Transaction</p>
      <SelectTypeAutocomplete
        style={globalStyles.simpleInputFull}
        name={'type'}
        label={'name'}
        custom={(item) => item?.name}
        title={'Transaction type*'}
        handleSelect={handleChange}
        defaultValue={inputs?.type}
        list={txnTypeEnums || []}
        typeError={error === 'type' ? 'Transaction type is required' : ' '}
        error={error}
        alwaysError={true}
      />
      <SelectTypeAutocomplete
        disabled={!inputs?.type}
        style={globalStyles.simpleInputFull}
        name={'officeId'}
        label={'name'}
        title={'Office*'}
        handleSelect={handleChange}
        defaultValue={inputs?.officeId}
        list={officesList?.offices || []}
        typeError={error === 'officeId' ? 'Cannot use this customer due to auto deny' : ' '}
        error={error}
        alwaysError={true}
      />
      {inputs?.type !== TxnTypeEnums.OTHERFEES && inputs?.type !== TxnTypeEnums.OFFICE_EXPENSE && inputs?.type !== TxnTypeEnums.SOFTWAREFEE &&
        <SelectTypeAutocomplete
          loadType={'GET_AGENT_BY_OFFICE'}
          disabled={!inputs?.officeId}
          style={globalStyles.simpleInputFull}
          name={'agentId'}
          label={'username'}
          title={`Agent${inputs?.type !== TxnTypeEnums.FLAT_FEE ? '*' : ''}`}
          handleSelect={handleChange}
          defaultValue={inputs?.agentId}
          list={officeAgentList || []}
          typeError={error === 'agentId' ? 'Cannot use this customer due to auto deny' : ' '}
          error={error}
          alwaysError={true}
        />
      }
      {inputs?.type !== TxnTypeEnums.OTHERFEES && inputs?.type !== TxnTypeEnums.OFFICE_EXPENSE && inputs?.type !== TxnTypeEnums.SOFTWAREFEE &&
        <SelectTypeAutocomplete
          loadType={'GET_MANUAL_PAYROLL_LOADS'}
          disabled={!inputs?.agentId}
          style={globalStyles.simpleInputFull}
          name={'loadId'}
          label={'displayId'}
          title={'Load*'}
          handleSelect={handleChange}
          defaultValue={inputs?.loadId}
          list={manualPayrollLoads || []}
          typeError={error === 'loadId' ? 'Cannot use this customer due to auto deny' : ' '}
          error={error}
          alwaysError={true}
        />
      }
      <ValidationInput
        className={inputs.amount && globalStyles.inputTextFieldBlue}
        onChange={handleChange}
        value={inputs.amount}
        typeError={error === 'amount' && ErrorText.field}
        label={'Amount*'}
        type={'number'}
        variant={'outlined'}
        name={'amount'}
        // style={classes.simpleInput}
      />
      <div style={{ marginTop: '-16px' }}>
        <ValidationInput
          className={inputs.description ? loadClass.inputDescriptionBlue : loadClass.inputDescription}
          onChange={handleChange}
          typeError={error === 'description' ? ' ' : ''}
          variant={'outlined'}
          name={'description'}
          Length={200}
          value={inputs.description}
          label={'Description here...'}
          type={'text'}
          multiline={true}
        />
      </div>
      <CreateChancel
        loader={!!loader.length}
        classes={globalStyles.buttonsStyle}
        create={'Create'}
        chancel={'Cancel'}
        onCreate={handleCreate}
        onClose={close}
      />
    </div>
  );
};