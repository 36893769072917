import {
    CREATE_MANUAL_PAYROLL,
    GET_MANUAL_PAYROLL_LOADS,
    GET_PAYROLL_LOADS,
    GET_PAYROLL_SUMMARY,
    GET_PAYROLLS,
} from './payroll.types';

/** Payroll Requests, here is All requests for Payroll page */


/** Get Payroll */

export const getPayrolls = (params) => {
    return {
        type: GET_PAYROLLS,
        payload: { params }
    }
}

export const getPayrollSummary = (params) => {
    return {
        type: GET_PAYROLL_SUMMARY,
        payload: {params}
    }
}
export const getPayrollLoads = (params) => {
    return {
        type: GET_PAYROLL_LOADS,
        payload: {params}
    }
}

export const getManualPayrollLoads = (params) => {
    return {
        type: GET_MANUAL_PAYROLL_LOADS,
        payload: {params}
    }
}

export const createManualPayroll = (params) => {
    return {
        type: CREATE_MANUAL_PAYROLL,
        payload: {params}
    }
}
/** End */